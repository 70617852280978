import React from 'react';
import About from '../../components/about/About';
import Activities from '../../components/activities/Activities';
import Customers from '../../components/customer/Customers';
import Hero from '../../components/hero/Hero';
import Partners from '../../components/partner/Partners';
import Projects from '../../components/project/Projects';

export default function Home({ t, lang }) {
  return (
    <>
      <Hero t={t} lang={lang} />
      <About t={t} lang={lang} />
      <Activities t={t} lang={lang} />
      <Projects t={t} />
      <Customers t={t} />
      <Partners t={t} />
    </>
  );
}
