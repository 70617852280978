import React from 'react';
import { useParams } from 'react-router';
import useFetchData from '../../api/ApiData';
import Activity from '../../components/activities/Activity';

export default function ActivitiesDetails({ t, lang }) {
  const { id } = useParams();
  // ----------------------------------------------------------------------------->
  const { data, error } = useFetchData(`services/${id}/sub-services`);
  return error ? (
    <div className='text-error'>{t('no-item')}</div>
  ) : (
    <div className='all-activities py-5'>
      <div className='container mt-5'>
        <h3
          className='title fw-bold text-white text-uppercase text-center pt-3'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {lang === 'en' ? data?.title_en : data?.title_ar}
        </h3>
        {data?.sub_services && (
          <Activity lang={lang} activities={data?.sub_services} />
        )}
      </div>
    </div>
  );
}
