import {
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaTelegramPlane,
} from 'react-icons/fa';
import { AiOutlineTwitter, AiOutlineInstagram } from 'react-icons/ai';
import { RiFacebookFill, RiWhatsappLine } from 'react-icons/ri';
import { GrYoutube } from 'react-icons/gr';
import useFetchData from '../../api/ApiData';

export default function Footer({ t }) {
  const { data: info, error } = useFetchData('informations');

  return error ? (
    <div className='text-error'>{t('no-item')}</div>
  ) : (
    <footer className='footer py-md-5 pt-5 pb-2' dir='ltr' id='contact-us'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md-2 mt-md-5 text-md-start p-md-0'>
            <img
              height={51}
              width={124.5}
              src='/assets/images/logo.png'
              alt='logo'
            />
          </div>
          <div className='col-md-6 p-md-0 '>
            <h3 className='title fw-bold mb-md-4 mb-2 mt-3 mt-md-0'>
              {t('contact-us')}
            </h3>
            <ul className='info list-unstyled mb-0'>
              <li>
                <a href={`tel:${info?.phone}`}>
                  <FaPhoneAlt /> {info?.phone}
                </a>
              </li>
              <li>
                <a href=''>
                  <FaMapMarkerAlt /> {info?.address}
                </a>
              </li>
              <li>
                <a href={`mailto:${info?.email}`}>
                  <FaEnvelope /> {info?.email}
                </a>
              </li>
            </ul>
          </div>
          <div className='col-md-4 align-self-end'>
            <ul className='social-links list-unstyled d-flex justify-content-center align-items-center gap-4'>
              {info?.social_media?.facebook && (
                <li>
                  <a
                    href={info?.social_media?.facebook}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <RiFacebookFill size='.9rem' />
                  </a>
                </li>
              )}

              {info?.social_media?.instagram && (
                <li>
                  <a
                    href={info?.social_media?.instagram}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram size='.9rem' />
                  </a>
                </li>
              )}
              {info?.social_media?.youtube && (
                <li>
                  <a
                    href={info?.social_media?.youtube}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <GrYoutube size='.9rem' />
                  </a>
                </li>
              )}
              {info?.social_media?.whatsapp && (
                <li>
                  <a
                    href={info?.social_media?.whatsapp}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <RiWhatsappLine size='.9rem' />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
