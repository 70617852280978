import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
  en: {
    translation: {
      home: 'Home',
      'about-us': 'About Us',
      about: 'About',
      'al-abbar': 'AL-ABBAR',
      activities : 'Activities ',
      partners: 'Our Partners',
      customers: 'Our customer',
      'contact-us': 'Contact us',
      gallery: 'Gallery',
      'photos-gallery': 'Photos Gallery',
      'no-item':
        'This section does not contain any content, please try again later',
    },
  },
  ar: {
    translation: {
      home: 'الرئيسية',
      'about-us': ' من نحن',
      about: 'عن',
      'al-abbar': 'العبار',
      activities : 'الأنشطة',
      customers: 'عملاؤنا المميزون',
      partners: 'شركائنا',
      'contact-us': 'تواصل معنا',
      gallery: 'اعمالنا',
      'photos-gallery': 'معرض الصور',
      'no-item': 'هذا القسم لايوجد به اي محتوي الرجاء المحاولة لاحقا',
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lang: 'en',
  fallbackLng: 'en',
});

export default i18n;
