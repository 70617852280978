import { FaLevelUpAlt } from 'react-icons/fa';

export default function ScrollTopTop() {
  // move to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div id='scroll-top'>
      <FaLevelUpAlt onClick={scrollToTop} />
    </div>
  );
}
