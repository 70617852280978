import React from 'react';
import useFetchData from '../../api/ApiData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function Customers({ t }) {
  const { data: customers, error } = useFetchData('customers');

  return error ? (
    <div className='text-error'>{t('no-item')}</div>
  ) : (
    <div className='customers py-5' id='customers'>
      <div className='container'>
        <h2
          className='text-center text-uppercase fw-bold'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {t('customers')}
        </h2>
        {customers && (
          <Swiper
            className='multi-swiper  mt-5'
            data-aos='fade-left'
            data-aos-delay='100'
            centeredSlides={true}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 7,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 7,
                spaceBetween: 50,
              },
            }}
          >
            {customers?.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  height={100}
                  width={100}
                  src={image}
                  alt='img-customers'
                  data-aos='fade-up'
                  data-aos-delay='100'
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
