import { useNavigate } from 'react-router';
import useFetchData from '../../api/ApiData';

export default function Activities({ t, lang }) {
  const navigate = useNavigate();
  const { data: activities, error } = useFetchData('services');

  return error ? (
    <div className='text-error'>{t('no-item')}</div>
  ) : (
    <div className='activities py-5' id='activities'>
      <div className='container'>
        <h2
          className='title text-uppercase text-center fw-bold'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {t('activities')}
        </h2>
        <div className='row mt-4'>
          {activities &&
            activities?.map((activity) => (
              <div
                className='col-md-4 '
                key={activity.id}
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/activities/${activity?.slug}`)}
              >
                <div
                  className='box text-center rounded-3'
                  data-aos='fade-right'
                  data-aos-delay='100'
                >
                  <div className='icon' data-aos='fade-up' data-aos-delay='100'>
                    <img
                      width={100}
                      height={100}
                      src={activity?.image}
                      alt={
                        lang === 'en' ? activity?.title_en : activity?.title_ar
                      }
                    />
                  </div>
                  <div className='info'>
                    <h3
                      className='name text-uppercase fw-bold mt-3'
                      data-aos='fade-up'
                      data-aos-delay='150'
                    >
                      {lang === 'en' ? activity?.title_en : activity?.title_ar}
                    </h3>
                    <p className='desc' data-aos='fade-up' data-aos-delay='200'>
                      {lang === 'en'
                        ? activity?.description_en
                        : activity?.description_ar}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
