import ApiConfig from './ApiConfig';
import { useEffect, useState } from 'react';

export default function useFetchData(url) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await ApiConfig.get(url);
        const { data } = await res?.data;
        setData(data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    })();
  }, [url]);
  return { data, error, isLoading };
}
