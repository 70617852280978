import React from 'react';
import useFetchData from '../../api/ApiData';
import Principles from '../principle/Principles';

export default function About({ t, lang }) {
  const { data: about, error } = useFetchData('about');

  return error ? (
    <div className='text-error'>{t('no-item')}</div>
  ) : (
    <div className='about pt-5' id='about'>
      <div className='container'>
        <h2
          className='title text-md-center'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {t('about')}
          <span className='text-uppercase'> {t('al-abbar')}</span>
        </h2>
        <p
          className='desc text-md-center m-auto'
          data-aos='fade-up'
          data-aos-delay='150'
        >
          {lang === 'en' ? about?.about_en : about?.about_ar}
        </p>
        <Principles t={t} lang={lang} />
      </div>
    </div>
  );
}
