import React, { useEffect, useState, useCallback } from 'react';
import $ from 'jquery';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaAlignCenter, FaRegImages, FaLanguage } from 'react-icons/fa';
import { AiOutlineHome } from 'react-icons/ai';
import { BsInfoSquare } from 'react-icons/bs';
import { FiTruck, FiUsers } from 'react-icons/fi';
import { BiMessageAltDots } from 'react-icons/bi';
import { useNavigate } from 'react-router';

export default function Navbar() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [tvLang, setTvLang] = useState('عربي');
  // ----------------------------------------------------------------------------->
  let lang = cookie.load('lang')
    ? cookie.load('lang')
    : cookie.save('lang', 'en');
  // ----------------------------------------------------------------------------->
  // effect render after change language
  const renderChangeLang = useCallback(() => {
    if (lang === 'en') {
      document.querySelector('body').style.direction = 'ltr';
      document.querySelector('html').setAttribute('lang', 'en');
      i18n.changeLanguage('en');
      setTvLang('عربي');
    } else if (lang === 'ar') {
      document.querySelector('body').style.direction = 'rtl';
      document.querySelector('html').setAttribute('lang', 'ar');
      i18n.changeLanguage('ar');
      setTvLang('English');
    }
  }, [i18n, lang]);
  useEffect(() => {
    renderChangeLang();
  }, []);
  // ----------------------------------------------------------------------------->
  useEffect(() => {
    // change background navbar desktop after page scroll top 100px
    document.addEventListener('scroll', handleScroll);
    // ------------------------------------------------------------------------->
    $('.navbar .navbar-collapse .navbar-nav .nav-link').on(
      'click',
      function () {
        // close menu mobile after click item
        $('.navbar-collapse').removeClass('show');
        $('.navbar-toggler').toggleClass('collapsed');
        $(this).parent().addClass('active').siblings().removeClass('active');
      }
    );
  }, []);
  // ----------------------------------------------------------------------------->
  const changeLanguage = () => {
    // change lang as select user click btn lang
    navigate(0);
    if (i18n.language === 'en') {
      cookie.save('lang', 'ar');
    } else if (i18n.language === 'ar') {
      cookie.save('lang', 'en');
    }
  };
  // ----------------------------------------------------------------------------->
  // calculate scroll top and show or hide btn scroll to top
  function handleScroll() {
    let scrollToTopBtn = document.getElementById('scroll-top');
    let navbar = document.querySelector('.navbar');
    let scrollableHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let GOLDEN_RATIO = 0.001;
    if (document.documentElement.scrollTop / scrollableHeight > GOLDEN_RATIO) {
      //show button
      navbar.setAttribute(
        'style',
        'background-color: #43286D; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
      );
      scrollToTopBtn.style.display = 'block';
    } else {
      //hide button
      navbar.style.backgroundColor = 'unset';
      scrollToTopBtn.style.display = 'none';
    }
  }
  return (
    <nav className='navbar navbar-expand-lg fixed-top'>
      <div className='container align-items-center'>
        <Link className='navbar-brand position-relative' to='/'>
          <img
            height={35}
            width={85}
            src='/assets/images/logo-lite.png'
            alt='logo-lite'
          />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <FaAlignCenter />
        </button>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarNavAltMarkup'
        >
          <div className='navbar-nav gap-md-4 gap-2'>
            <Link className='nav-link active' aria-current='page' to='/'>
              <AiOutlineHome /> {t('home')}
            </Link>
            <Link className='nav-link' to='/#about' data-value='about'>
              <BsInfoSquare /> {t('about-us')}
            </Link>
            <Link
              className='nav-link'
              to='/#activities'
              data-value='activities'
            >
              <FiTruck /> {t('activities')}
            </Link>
            <Link className='nav-link' to='/#gallery' data-value='gallery'>
              <FaRegImages /> {t('gallery')}
            </Link>
            <Link className='nav-link' to='/#customers' data-value='customers'>
              <FiUsers /> {t('customers')}
            </Link>
            <Link className='nav-link' to='/#partners' data-value='partners'>
              <FiUsers /> {t('partners')}
            </Link>
            <Link
              className='nav-link'
              to='/#contact-us'
              data-value='contact-us'
            >
              <BiMessageAltDots /> {t('contact-us')}
            </Link>
            <span
              className='lang rounded-pill px-4 py-2'
              onClick={changeLanguage}
            >
              {tvLang} <FaLanguage />
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
}
