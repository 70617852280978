import React from 'react';
import useFetchData from '../../api/ApiData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function Hero({ t, lang }) {
  const { data, error } = useFetchData('hero');

  return error ? (
    <div className='text-error'>{t('no-item')}</div>
  ) : (
    <main className='hero'>
      <div className='container d-flex align-items-center'>
        <div className='row align-items-center h-100 pt-5'>
          <div className='col-md-6'>
            <h1
              className='title fw-bolder'
              data-aos='fade-right'
              data-aos-delay='100'
            >
              {lang === 'en' ? data?.hero_en : data?.hero_ar}
            </h1>
          </div>
          <div className='col-md-6'>
            {data?.hero_images && (
              <Swiper
                className='single-swiper'
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
              >
                {data?.hero_images?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      height={390}
                      width={550}
                      className='img-thumbnail'
                      src={image}
                      alt='img-slider'
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
export default React.memo(Hero);
