import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function Activity({ lang, activities }) {
  return (
    <div className='row mt-5'>
      {activities &&
        activities?.map((activity) => (
          <div key={activity?.id} className='col-md-6 mb-4'>
            <div
              className='box text-center pb-4'
              data-aos='fade-right'
              data-aos-delay='100'
            >
              {activity?.images && (
                <Swiper
                  className='single-swiper'
                  style={{ width: '100%' }}
                  data-aos='fade-left'
                  data-aos-delay='100'
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  {activity?.images?.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div data-aos='fade-up' data-aos-delay='200'>
                        <img
                          src={image}
                          alt={
                            lang === 'en'
                              ? activity?.title_en
                              : activity?.title_ar
                          }
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
              <div className='info px-4'>
                <h3
                  className='name text-white fw-bold text-uppercase'
                  data-aos='fade-up'
                  data-aos-delay='150'
                >
                  {lang === 'en' ? activity?.title_en : activity?.title_ar}
                </h3>
                <p
                  className='desc text-white-50'
                  data-aos='fade-up'
                  data-aos-delay='200'
                >
                  {lang === 'en'
                    ? activity?.description_en
                    : activity?.description_ar}
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
