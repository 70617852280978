import React from 'react';
import useFetchData from '../../api/ApiData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function Projects({ t }) {
  const { data: projects, error } = useFetchData('previous-projects');

  return error ? (
    <div className='text-error'>{t('no-item')}</div>
  ) : (
    <div className='gallery py-5' id='gallery'>
      <div className='container'>
        <h2
          className='text-center text-uppercase fw-bold text-white'
          data-aos='fade-up'
          data-aos-delay='100'
        >
          {t('photos-gallery')}
        </h2>
        {projects && (
          <Swiper
            className='single-swiper pb-5 mt-5'
            data-aos='fade-left'
            data-aos-delay='100'
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
          >
            {projects?.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  height={470}
                  width={954}
                  className='img-thumbnail'
                  src={image}
                  alt='img-project'
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
