import React from 'react';
import useFetchData from '../../api/ApiData';

export default function Principles({ t, lang }) {
  const { data: principles, error } = useFetchData('principles');

  return error ? (
    <div className='text-error'>{t('no-item')}</div>
  ) : (
    <div className='row about-wrapper py-5 mt-5 text-white'>
      {principles?.map((principle) => (
        <div
          key={principle?.id}
          className='col-md-6 d-md-flex gap-md-3 mt-5 mt-md-0'
        >
          <div className='icon' data-aos='fade-up' data-aos-delay='100'>
            <img
              src={principle?.image}
              height={100}
              width={100}
              alt={lang === 'en' ? principle?.title_en : principle?.title_ar}
            />
          </div>
          <div className='info'>
            <h3
              className='name fw-bold'
              data-aos='fade-up'
              data-aos-delay='150'
            >
              {lang === 'en' ? principle?.title_en : principle?.title_ar}
            </h3>
            <p
              className='desc text-white-50'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              {lang === 'en'
                ? principle?.description_en
                : principle?.description_ar}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
