import { useState } from 'react';
import Aos from 'aos';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import cookie from 'react-cookies';
import ActivitiesDetails from './pages/activities/Details';
import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import ScrollTopTop from './components/scroll-to-top/ScrollTopTop';

function App() {
  const { t } = useTranslation();
  const [lang, setLang] = useState();
  // ----------------------------------------------------------------------------->
  useEffect(() => {
    setLang(
      cookie.load('lang') ? cookie.load('lang') : cookie.save('lang', 'en') // check type language
    );
  }, []);
  // ----------------------------------------------------------------------------->
  useEffect(() => {
    Aos.init(); // initial aos animation library
  }, []);
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route element={<Home t={t} lang={lang} />} path='/' />
        <Route
          element={<ActivitiesDetails t={t} lang={lang} />}
          path='/activities/:id'
        />
      </Routes>
      <Footer t={t} />
      <ScrollTopTop />
    </BrowserRouter>
  );
}

export default App;
